
import patientAgreeConsent from '../assets/images/patient-agree-consent.svg';
import patientDisagreeConsent from '../assets/images/patient-disagree-consent.svg'
import patientNoneConsent from '../assets/images/patient-none-consent.svg'
import patientNoConsent from '../assets/images/patient-no-consent.svg'

const AGREE = "AGREE";
const DISAGREE = "DISAGREE"
const NONE =  "NONE";
const NOCONSENT =  "NOCONSENT";
const PATIENT_CONSENT = "Patient consent";

const ALLOWED_ABRIDGE_STATUSES = [AGREE, DISAGREE, NOCONSENT, NONE];

const ABRIDGE_STATUS_OPTIONS = {
    [AGREE]: {
        header: "The patient has consented to using Abridge.",
        info: "You may launch the Abridge application now.",
        icon: patientAgreeConsent
    },
 
    [DISAGREE]: {
        header: "The patient has declined the use of Abridge.",
        info: "DO NOT launch the Abridge application at this time.",
        icon: patientDisagreeConsent
    },
    [NOCONSENT]: {
        header: "The patient has not yet provided a response to Abridge consent.",
        info: "If you intend to use Abridge, please discuss with the patient and document accordingly.",
        icon: patientNoConsent
    },
 
    [NONE]: {
        header: "The patient has NOT joined the video visit and has not yet provided a response to Abridge consent.",
        info: 'Please select "Refresh" once the patient has joined.',
        icon: patientNoneConsent
    }
}

export {
    AGREE,
    DISAGREE,
    NONE,
    NOCONSENT,
    ALLOWED_ABRIDGE_STATUSES,
    PATIENT_CONSENT,
    ABRIDGE_STATUS_OPTIONS
}
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../../constants/store.constants";
import { COPY_OPTIONS, COPIED_TEXT_TIMEOUT } from "../../constants/providerInfo.constants";
import photo from "../../assets/images/dr-photo-avatar-medium.svg";
import { getClinicianDetailnextOncall } from "../../service/clinician.service";
import {
  getUTCTimestamp,
  formatNextOncallDate,
  copyToClipboard,
  getDefaultSrc,
  formatOnCallDate,
  updateCopiedStatus,
  handlePhoneClick,
  handleEmailClick,
} from "../../helper/oncallUtils";
import { isDeviceMobile } from "../../helper/global.utilities";
import Loader from "../Loader/Loader";
import DetailedInfo from "../DetailedInfo/DetailedInfo";
import { prepareC2CDependencies } from "../../helper/inviteHelper";

const ClinicianInfo = () => {
  const [nextOnCallFormattedDate, setNextOnCallFormattedDate] = useState("");
  const [onCallFormattedDate, setOnCallFormattedDate] = useState("");
  const [copyOptions, setCopyOptions] = useState(COPY_OPTIONS);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [loader, setLoader] = useState(false);
  const textCopiedTimerRef = useRef();

  const { selectedClinicianData } = useSelector(
    (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]
  );

  const { deviceType } = useSelector(
    (state) => state?.[STORE_NAMES.ZOOM_CLIENT]
  );

  const {
    hideOncall,
    directoryRegion: region = null, 
    featureFlags, 
    clinicianToClinicianInvite: clinicianToClinicianInviteConfig 
  } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

  const {
    department: { mc: { name = null } } = {},
    isCurrentlyOnCall = null,
    imageURL = null,
    firstName = "",
    lastName = "",
    contactDetails: {
      officePhone = null,
      mobilePhone = null,
      email = null,
      pager: { pager: pagerNumber = null } = {},
    } = {},
    notes = null,
    isFavorite = null,
  } = selectedClinicianData;

  const fetchClinicianDetailnextOncall = (selectedClinicianData) => {
    setLoader(true);
    setCopyOptions(COPY_OPTIONS);
    setNextOnCallFormattedDate(""); //reset old state
    const { startDateTimeUTC, endDateTimeUTC } = getUTCTimestamp(new Date());
    const params = {
      startDateTime: startDateTimeUTC,
      endDateTime: endDateTimeUTC,
      accountNumber: selectedClinicianData.acctno,
      limit: 10,
      offset: 0,
      region,
    };
    getClinicianDetailnextOncall(params, callBackFn);
  };

  const callBackFn = (response) => {
    setLoader(false);
    const { data = null } = response;
    setNextOnCallFormattedDate(formatNextOncallDate(data));
  };

  const startTimerToHideTextCopiedChip = (copyType) => {
    if (textCopiedTimerRef.current) {
      clearTimeout(textCopiedTimerRef.current);
      textCopiedTimerRef.current = null;
    }
    textCopiedTimerRef.current = setTimeout(
      () => setCopyOptions(updateCopiedStatus(copyType, false, copyOptions)),
      COPIED_TEXT_TIMEOUT
    );
  };

  const handleCopyClick = (copyType, text) => {
    copyToClipboard(copyType, text);
    setCopyOptions(updateCopiedStatus(copyType, true, copyOptions));
    startTimerToHideTextCopiedChip(copyType);
  };

  useEffect(() => {
    if (isCurrentlyOnCall) {
      const starDateTime =
        selectedClinicianData.currentOnCallSchedule?.startDateTime;
      const endDateTime =
        selectedClinicianData.currentOnCallSchedule?.endDateTime;
      const format = formatOnCallDate(starDateTime, endDateTime);
      setOnCallFormattedDate(format);
    } else {
      setOnCallFormattedDate("");
    }
    fetchClinicianDetailnextOncall(selectedClinicianData);
  }, [selectedClinicianData]);

  useEffect(() => {
    setIsMobileDevice(isDeviceMobile(deviceType));
  }, []);

  return (
    <div
      className={`${
        loader ? "vve-container" : ""
      } vve-clinician-info-container`}
    >
      {loader ? (
        <Loader showLoader={loader} />
      ) : (
        <DetailedInfo
          isCurrentlyOnCall={isCurrentlyOnCall}
          imageURL={imageURL}
          photo={photo}
          getDefaultSrc={getDefaultSrc}
          firstName={firstName}
          lastName={lastName}
          name={name}
          officePhone={officePhone}
          copyOptions={copyOptions}
          handleCopyClick={handleCopyClick}
          mobilePhone={mobilePhone}
          email={email}
          pagerNumber={pagerNumber}
          isMobileDevice={isMobileDevice}
          onCallFormattedDate={onCallFormattedDate}
          nextOnCallFormattedDate={nextOnCallFormattedDate}
          region={region}
          notes={notes}
          handlePhoneClick={handlePhoneClick}
          featureFlags={featureFlags}
          handleEmailClick={handleEmailClick}
          hideOncall={hideOncall}
          isFavorite={isFavorite}
          c2cDependencies={prepareC2CDependencies({selectedClinicianData, clinicianToClinicianInviteConfig})}
        />
      )}
    </div>
  );
};

export default ClinicianInfo;

import { ACTION_TYPES } from '../../constants/store.constants';
import { getLanguages, postInterpreterApi } from '../../service/interpreter.service';
import setErrorAction from "./errorActions";

const setInterpreterType = (data) => ({
	type: ACTION_TYPES.SET_INTERPRETER_TYPE,
	payload: data
});

const setInterpreterLanguage = (data) => (dispatch) => {
	dispatch({ type: ACTION_TYPES.SET_INTERPRETER_LANGUAGE, payload: data });
	const inviteButtonStatus = data ? true : false;
	dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: inviteButtonStatus });
};

const setInterpreterSearchQuery = (data) => ({
	type: ACTION_TYPES.SET_INTERPRETER_SEARCH_QUERY,
	payload: data
});

const setServiceAreaIsLoading = (data) => ({
	type: ACTION_TYPES.SET_SERVICE_AREA_IS_LOADING,
	payload: data
});

const setInterpreterLanguageList = (data) => ({
	type: ACTION_TYPES.SET_INTERPRETER_LANGUAGE_LIST,
	payload: data
});

const setDisplayLanguageList = (language) => ({
	type: ACTION_TYPES.SET_INTERPRETER_DISPLAY_LIST,
	payload: language
})


const fetchInterpreterLanguages = (interpreterLanguageList, payload) => async (dispatch) => {
	const preSelectLanguage = (data) => dispatch(setInterpreterLanguage(data));
	const setDisplayData = (data) => dispatch({
		type: ACTION_TYPES.SET_INTERPRETER_DISPLAY_LIST,
		payload: data
	});
	const setInterpreterLanguageData = (data) => dispatch({
		type: ACTION_TYPES.SET_INTERPRETER_LANGUAGE_LIST,
		payload: data
	});
	try {
		let data = null;
		if (interpreterLanguageList?.length > 0) {
			data = interpreterLanguageList;
		} else {
			data = await getLanguages(payload);
		}
		if (!data?.error && data.length > 0) {
			setInterpreterLanguageData(data);
			preSelectLanguage(data[0]);
			setDisplayData(data);
			return data;
		}
	} catch (err) {
		dispatch(setErrorAction.setErrorHandler(err));
	}
}

const postInterpreterAction = (interpreterPayload) => async (dispatch) => {
	try {
		if (interpreterPayload) {
			dispatch({type: ACTION_TYPES.SET_MENU_ITEM_DISABLE, payload: false});
			dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false});
			dispatch({ type: ACTION_TYPES.DISABLE_LANGUAGE_LIST, payload:true });
			const response = await postInterpreterApi(interpreterPayload);
				if (response?.status) {
					dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: {inviteButtonClicked: true, status: true} });
				}
		}
	} catch (err) {
		dispatch({type: ACTION_TYPES.SET_MENU_ITEM_DISABLE, payload: true});
		dispatch(setErrorAction.setErrorHandler(err));
	}
}
const disableLanguageList = (data) => ({
	type: ACTION_TYPES.DISABLE_LANGUAGE_LIST,
	payload: data
});

export default {
	setInterpreterType,
	setInterpreterLanguage,
	setInterpreterSearchQuery,
	setServiceAreaIsLoading,
	setInterpreterLanguageList,
	fetchInterpreterLanguages,
	setDisplayLanguageList,
	postInterpreterAction,
	disableLanguageList
};
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { NotificationServiceDownModal, FailedMultiInvitesModal, NotificationTrackingModal } from "./MultiInviteModals";
import GuestAction from '../../store/actions/GuestAction';
import {
    checkForAllNotificationsSentStatus,
    filterTrackingDetailsByStatus,
    prepareMultiInviteStatusCheckRequest,
    updateTrackingDetailsStatus
} from '../../helper/inviteHelper';
import { checkMultiInviteStatus } from '../../service/guest.service';
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';
import {
    MULTI_INVITE_TYPE,
    MULTI_INVITE_OPTIONS,
    DISPLAY_FAILED_NOTIFICATIONS_STATUSES,
    MAX_TIME_LIMIT_CHECKER_INTERVAL,
    ALLOWED_POLLING_STATUSES,
    POLLING_START_DELAY,
    NOTIFICATIONS_SENT_STATUSES
} from '../../constants/guest.constants';
import AddGuestAndPatient from "../AddGuestandPatient/AddGuestAndPatient";

const MultiParticipantInvite = () => {
    const dispatch = useDispatch();
    const { headerText,
        infoText } = MULTI_INVITE_OPTIONS;
    const { multiInviteTrackingDetails } = useSelector(state => state?.[STORE_NAMES.GUEST]);
    const {
        appConfig: {
            clientSettings: {
                dnp: {
                    statusCheckLimit = null,
                    pollingRetryInterval = null,
                    statusCheckTimeout = null
                } = {}
            } = {}
        }
    } = useSelector((state) => state?.[STORE_NAMES.APP]);

    const trackMultiInviteTimerRef = useRef([]);
    const statusRequestControllerRef = useRef([]);
    const maxTimeLimitCheckTimerRef = useRef(null);
    const [failedNotifications, setFailedNotifications] = useState(null);

    // modal toggles
    const [displayNotificationTrackingModal, setDisplayNotificationTrackingModal] = useState(false);
    const [displayNotificationServiceDownModal, setDisplayNotificationServiceDownModal] = useState(false);
    const [displayFailedNotificationsModal, setDisplayFailedNotificationsModal] = useState(false);

    /****************************************** Polling logic here *****************************************************/

    const startMaxTimeLimitCheckTimer = (startTimeStamp) => {
        clearMaxTimeLimitCheckerTimer();
        maxTimeLimitCheckTimerRef.current = setInterval(() => {
            const currentTimeStamp = dayjs();
            const maxTimeLimitForPolling = pollingRetryInterval * statusCheckLimit;
            if (currentTimeStamp.diff(startTimeStamp, "ms") >= maxTimeLimitForPolling) {
                stopNotificationStatusPolling("maxTimeLimitReached");
            }
        }, MAX_TIME_LIMIT_CHECKER_INTERVAL);
    };

    const clearMaxTimeLimitCheckerTimer = () => {
        if (maxTimeLimitCheckTimerRef.current) {
            clearInterval(maxTimeLimitCheckTimerRef.current);
        }
        maxTimeLimitCheckTimerRef.current = null;
    };

    const cancelAllStatusRequests = () => {
        statusRequestControllerRef.current.forEach(abortRequestController => abortRequestController.abort());
        statusRequestControllerRef.current = [];
    };

    const clearStatusCheckTimers = () => {
        trackMultiInviteTimerRef.current.forEach(timerId => clearTimeout(timerId));
        trackMultiInviteTimerRef.current = [];
    }

    const stopNotificationStatusPolling = (type) => {

        // clear all timers and cancel all pending network requests for status check
        cancelAllStatusRequests();
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();

        // reset all modals
        setDisplayNotificationTrackingModal(false);
        setFailedNotifications(null);

        switch (type) {
            case "allInvitesSent":
                // display successfull invitation modal
                dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
                break;
            case "maxTimeLimitReached": // same as failedNotificationsError case so fallthrough
                dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
            case "noMoreNotificationsToProcess": // same as failedNotificationsError case so fallthrough
            case "failedNotificationsError":
                const failedTrackingDetails = filterTrackingDetailsByStatus(multiInviteTrackingDetails, DISPLAY_FAILED_NOTIFICATIONS_STATUSES);
                if (failedTrackingDetails.length > 0) {
                    setFailedNotifications(failedTrackingDetails);
                    setDisplayFailedNotificationsModal(true);
                }
                else {
                    // display successfull invitation modal
                    dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
                }
                break;
            case "serviceDownError":
                setDisplayNotificationServiceDownModal(true);
                break;
            default:
                break;
        }
        // clear guest invite notifiation details from store
        dispatch(GuestAction.clearMultiInviteTrackingDetails());
    };

    const handleNoticaitionStatusSuccess = (successResponse) => {
        updateTrackingDetailsStatus(multiInviteTrackingDetails, successResponse);

        const lastSuccessfulInvites = multiInviteTrackingDetails
              .filter(({status}) => NOTIFICATIONS_SENT_STATUSES.includes(status));

        dispatch(GuestAction.setLastSuccessfulInvites(lastSuccessfulInvites));

        const allInvitesSentStatus = checkForAllNotificationsSentStatus(multiInviteTrackingDetails);
        if (allInvitesSentStatus === true) {
            stopNotificationStatusPolling("allInvitesSent");
            return;
        }

        const notificationsToProcess = filterTrackingDetailsByStatus(multiInviteTrackingDetails, ALLOWED_POLLING_STATUSES);
        if (notificationsToProcess.length === 0) {
            stopNotificationStatusPolling("noMoreNotificationsToProcess");
            return;
        }
    };

    const handleNotificationStatusError = (exception, isLastStatusCheck) => {
        if (isLastStatusCheck) {
            const serverErrors = exception?.response?.data?.errors;
            if (serverErrors && Array.isArray(serverErrors) && serverErrors.length > 0) {
                stopNotificationStatusPolling("serviceDownError");
            }
        }
    };

    const parseNotificationStatusResponse = ({ successResponse = null, exception = null, isLastStatusCheck }) => {
        if (successResponse && Array.isArray(successResponse) && successResponse.length > 0) {
            handleNoticaitionStatusSuccess(successResponse);
        }

        if (exception) {
            handleNotificationStatusError(exception, isLastStatusCheck);
        }
    };

    const checkNotificationStatus = async ({ multiInviteStatusCheckRequest, isLastStatusCheck }) => {
        const responseBody = {};
        try {
            const abortRequestController = new AbortController();
            statusRequestControllerRef.current.push(abortRequestController);
            responseBody.successResponse = await checkMultiInviteStatus({ payload: multiInviteStatusCheckRequest, customTimeout: statusCheckTimeout, abortRequestController });
        }
        catch (exception) {
            responseBody.exception = exception;
        }
        finally {
            parseNotificationStatusResponse({ ...responseBody, isLastStatusCheck });
        }
    };

    const startMultiInviteStatusCheckPolling = async (iterationCount) => {
        iterationCount++;
        const isLastStatusCheck = iterationCount === +statusCheckLimit;

        const multiInviteStatusCheckRequest = prepareMultiInviteStatusCheckRequest(multiInviteTrackingDetails, isLastStatusCheck);
        if (multiInviteStatusCheckRequest.trackingIds.length === 0) {
            stopNotificationStatusPolling("noMoreNotificationsToProcess");
            return;
        }

        // Trigger polling call here
        checkNotificationStatus({ multiInviteStatusCheckRequest, iterationCount, isLastStatusCheck });

        // stop recursion for safety
        if (isLastStatusCheck) {
            return;
        }

        // create next polling interval
        const pollingTimerId = setTimeout(startMultiInviteStatusCheckPolling, pollingRetryInterval, iterationCount);
        trackMultiInviteTimerRef.current.push(pollingTimerId);
    };

    /******************************************* Polling logic end ****************************************************/

    const handleFailedInvitesModalClose = () => {

        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayFailedNotificationsModal(false);

        // enable the invite button after modal is closed
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
    };

    const handleNotificationServiceDownModalClose = () => {

        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayNotificationServiceDownModal(false);

        // enable the invite button after modal is closed
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
    };

    // polling logic for multi invite status check
    useEffect(() => {
        let firstPollDelayTimer;
        if (multiInviteTrackingDetails && statusCheckLimit && pollingRetryInterval && statusCheckTimeout && firstPollDelayTimer == null && !displayNotificationTrackingModal) {
            firstPollDelayTimer = setTimeout(() => {
                setDisplayNotificationTrackingModal(true);
                const startTimeStamp = dayjs();
                startMaxTimeLimitCheckTimer(startTimeStamp);
                startMultiInviteStatusCheckPolling(0);
            }, POLLING_START_DELAY);
        }

        return () => {
            clearTimeout(firstPollDelayTimer);
        }
    }, [multiInviteTrackingDetails]);

    // initialization logic
    useEffect(() => {
        dispatch(GuestAction.setGuestInviteType(MULTI_INVITE_TYPE));
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false });
        return () => cleanUpMultiInviteDependencies();
    }, []);

    const cleanUpMultiInviteDependencies = () => {

        // cleanup polling dependencies
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();
        cancelAllStatusRequests();
        setDisplayNotificationTrackingModal(false);
        dispatch(GuestAction.clearMultiInviteTrackingDetails());
    };

    return <div className='vve-invite-guest-text-container'>
        <p className='vve-multi-invite-header'>{headerText}</p>
        <p className='vve-info vve-medium'>{infoText}</p>
        <AddGuestAndPatient />
        {displayFailedNotificationsModal &&
            <FailedMultiInvitesModal failedNotifications={failedNotifications} onCloseHandler={handleFailedInvitesModalClose} />
        }
        {displayNotificationServiceDownModal &&
            <NotificationServiceDownModal onCloseHandler={handleNotificationServiceDownModalClose} />
        }
        {displayNotificationTrackingModal &&
            <NotificationTrackingModal onCloseHandler={setDisplayNotificationTrackingModal} />
        }
    </div>
};

export default MultiParticipantInvite;
import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	enableInviteButton: false,
	inviteButtonClicked: false,
	status: false,
	menuItemDisableOnSnow: true
};

const InviteReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.ENABLE_INVITE_BUTTON:
			return {
				...state,
				enableInviteButton: payload || false, 
			};
		case ACTION_TYPES.INVITE_BUTTON_CLICKED:
			return {
				...state,
				inviteButtonClicked: payload.inviteButtonClicked,
				status: payload.status,
				loading: payload.loading || false,
			};
		case ACTION_TYPES.SET_MENU_ITEM_DISABLE:
			return {
				...state,
				menuItemDisableOnSnow: payload || false, 
			};
		default:
			return state;
	}
};

export default InviteReducer;

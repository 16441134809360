/* Module Dependencies */
import React from 'react';
import PropTypes from "prop-types";

/* Custom Components */
import Image from "../Image/Image";
import TextCopiedChip from "../TextCopiedChip/TextCopiedChip";
import IfCondition from "../IfCondition/IfCondition";
import ClinicianToClinicianInvite from '../ClinicianToClinicianInvite/ClinicianToClinicianInvite';

/* Utils */
import { removeCommaIfFirstNameEmpty } from "../../helper/oncallUtils";
import { PROVIDER_INFO } from "../../constants/providerInfo.constants";
import { getFormattedPhoneNumber, getTruncatedMobileNumbers } from "../../helper/commonHelper";

/* Images */
import photo from "../../assets/images/dr-photo-avatar-medium.svg";
import CopySmall from "../../assets/images/copy-small.svg";
import CallButton from "../../assets/images/call-button.svg";
import Envelope from "../../assets/images/envelope.svg";
import filledStar from "../../assets/images/filledStar.svg";


/* Declare the component */
const DetailedInfo = ({ ...props }) => {
  // Re-structure props
  const {
    departmentName,
    isCurrentlyOnCall,
    imageURL,
    getDefaultSrc,
    firstName,
    lastName,
    role,
    name,
    order,
    officePhone,
    copyOptions,
    handleCopyClick,
    mobilePhone,
    email,
    pagerNumber,
    onCallFormattedDate,
    nextOnCallFormattedDate,
    notes,
    handlePhoneClick,
    featureFlags,
    handleEmailClick,
    hideOncall,
    isFavorite,
    c2cDependencies
  } = props;

  const MAIL_TO_LINK = `mailto:${email}`;

  // Check if any copiedStatus is true - For mobileview
  const hasCopiedStatus = Object.values(copyOptions).some((item) => item.copiedStatus);

  // Get the label value if any copiedStatus is true- - For mobileview
  const labelValue = hasCopiedStatus
    ? Object.values(copyOptions).find((item) => item.copiedStatus).label
    : null;

  return (
    <div className="vve-detailedInfo-container">

      {/* Department Name */}
      <div className={`vve-detailedInfo-header ${!departmentName ? "bg-transparent" : ""}`}>
        <IfCondition condition={departmentName}>
          <p>{departmentName}</p>
        </IfCondition>
      </div>

      {/* Profile Photo */}
      <div
        className={`vve-drphoto ${isCurrentlyOnCall && !hideOncall ? "vve-live" : ""} ${!departmentName ? "mt-0" : ""
          }`}
      >
        <Image
          className="vve-icon"
          src={imageURL ? imageURL : photo}
          role="img"
          defaultImgSrc={getDefaultSrc}
          alt="dr-photo-avatar-medium"
        />
      </div>

      {/* Full Name */}
      <div className="vve-firstname">
        <div className="vve-frstnamelastname-div">
          {removeCommaIfFirstNameEmpty(lastName, firstName)}
        </div>
        {isCurrentlyOnCall && !hideOncall ? <p>{PROVIDER_INFO.onCallNow.label}</p> : null}
        <div>
          <Image
            className={isFavorite ? "vve-filledStar-icon" : null}
            src={isFavorite ? filledStar : null}
            role="img"
            alt={isFavorite ? "filledStar.svg" : null}
          />
        </div>
      </div>

      {/* Role */}
      <IfCondition condition={role}>
        <div className="vve-role">
          {PROVIDER_INFO.role.label} - {role}
        </div>
      </IfCondition>

      {/* Call Order */}
      <IfCondition condition={order}>
        <div className="vve-callorder">
          {PROVIDER_INFO.callNumber.label} - {order}
        </div>
      </IfCondition>

      {/* Mobile only copied status*/}
      <TextCopiedChip
        copiedStatus={hasCopiedStatus}
        label={labelValue}
        customStyle={'vve-mobile-only'}
      ></TextCopiedChip>

      {/* Name */}
      <IfCondition condition={name}>
        <div className="vve-name">{name}</div>
      </IfCondition>

      {/* Office Phone */}
      <IfCondition condition={officePhone}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.office.label}
            {
              featureFlags?.oneClickPhone
                ? <p className="black-text">{getFormattedPhoneNumber(officePhone)}</p>
                : <p className="text-decoration-none blue-text">
                  {getFormattedPhoneNumber(officePhone)}
                </p>
            }
          </div>
          <TextCopiedChip
            copiedStatus={
              copyOptions[PROVIDER_INFO.office.copyType].copiedStatus
            }
            label={copyOptions[PROVIDER_INFO.office.copyType].label}
          ></TextCopiedChip>
          <div>
            <IfCondition condition={featureFlags?.oneClickPhone}>
              <Image
                className="vve-call-icon"
                id="call-button-icon"
                src={CallButton}
                role="img"
                alt="call-button.svg"
                onClick={() =>
                  handlePhoneClick(officePhone)
                }
              />
            </IfCondition>
            <Image
              className="vve-copypaste-icon"
              id="office-phone-copy-icon"
              src={CopySmall}
              role="img"
              alt="copy-small.svg"
              onClick={() =>
                handleCopyClick(PROVIDER_INFO.office.copyType, officePhone)
              }
            />
          </div>
        </div>
      </IfCondition>


      {/* Mobile Phone */}
      <IfCondition condition={mobilePhone}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.mobile.label}
            {
              featureFlags?.oneClickPhone
                ? <p className="black-text">{getFormattedPhoneNumber(getTruncatedMobileNumbers(mobilePhone))}</p>
                : <p className="text-decoration-none blue-text">
                  {getFormattedPhoneNumber(getTruncatedMobileNumbers(mobilePhone))}
                </p>
            }
          </div>
          <TextCopiedChip
            copiedStatus={
              copyOptions[PROVIDER_INFO.mobile.copyType].copiedStatus
            }
            label={copyOptions[PROVIDER_INFO.mobile.copyType].label}
          ></TextCopiedChip>
           {/* Video Icon left to  Phone icon*/}
          <div>
            <IfCondition condition={c2cDependencies?.enableC2CInvite}>
              <ClinicianToClinicianInvite {...c2cDependencies} />
            </IfCondition>
            <IfCondition condition={featureFlags?.oneClickPhone}>
              <Image
                className="vve-call-icon"
                id="call-button-icon"
                src={CallButton}
                role="img"
                alt="call-button.svg"
                onClick={() =>
                  handlePhoneClick(mobilePhone)
                }
              />
            </IfCondition>
            <Image
              className="vve-copypaste-icon"
              id="mobile-copy-icon"
              src={CopySmall}
              role="img"
              alt="copy-small.svg"
              onClick={() =>
                handleCopyClick(PROVIDER_INFO.mobile.copyType, mobilePhone)
              }
            />
          </div>
        </div>
      </IfCondition>

      {/* Email */}
      <IfCondition condition={email}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.email.label}
            <p className="black-text">{email}</p>
          </div>
          <TextCopiedChip
            copiedStatus={
              copyOptions[PROVIDER_INFO.email.copyType].copiedStatus
            }
            label={copyOptions[PROVIDER_INFO.email.copyType].label}
          ></TextCopiedChip>
          <div>
            <IfCondition condition={featureFlags?.oneClickEmail}>
              <Image
                className="vve-call-icon"
                id="envelope-icon"
                src={Envelope}
                role="img"
                alt="envelope.svg"
                onClick={() =>
                  handleEmailClick(MAIL_TO_LINK)
                }
              />
            </IfCondition>
            <Image
              className="vve-copypaste-icon"
              id="email-copy-icon"
              src={CopySmall}
              role="img"
              alt="copy-small.svg"
              onClick={() =>
                handleCopyClick(PROVIDER_INFO.email.copyType, email)
              }
            />
          </div>
        </div>
      </IfCondition>

      {/* Pager Number */}
      <IfCondition condition={pagerNumber}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.pager.label}
            <p className="black-text">{getFormattedPhoneNumber(pagerNumber)}</p>
          </div>
          <TextCopiedChip
            copiedStatus={
              copyOptions[PROVIDER_INFO.pager.copyType].copiedStatus
            }
            label={copyOptions[PROVIDER_INFO.pager.copyType].label}
          ></TextCopiedChip>
          <div>
            <Image
              className="vve-copypaste-icon"
              id="pager-copy-icon"
              src={CopySmall}
              role="img"
              alt="copy-small.svg"
              onClick={() =>
                handleCopyClick(PROVIDER_INFO.pager.copyType, pagerNumber)
              }
            />
          </div>
        </div>
      </IfCondition>
      {/* OnCall Date */}
      <IfCondition condition={onCallFormattedDate && !hideOncall}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.onCall.label}
            <p
              className={`${isCurrentlyOnCall ? "green-text" : "vve-contacts-header"
                }`}
            >
              {onCallFormattedDate}
            </p>
          </div>
        </div>
      </IfCondition>

      {/* NextOnCall Date */}
      <IfCondition condition={nextOnCallFormattedDate && !hideOncall}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.nextOnCall.label}
            <p className="vve-contacts-header">{nextOnCallFormattedDate}</p>
          </div>
        </div>
      </IfCondition>

      {/* Notes */}
      <IfCondition condition={notes && notes?.trim() != ""}>
        <div className="vve-contacts">
          <div className="vve-contacts-header">
            {PROVIDER_INFO.notes.label}
            <p className="vve-contacts-header">{notes}</p>
          </div>
        </div>
      </IfCondition>
    </div>
  );
};

/* PropTypes */
DetailedInfo.propTypes = {
  departmentName: PropTypes.string,
  isCurrentlyOnCall: PropTypes.bool,
  imageURL: PropTypes.string,
  getDefaultSrc: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.number,
  officePhone: PropTypes.string,
  copyOptions: PropTypes.shape({
    email: PropTypes.shape({
      copiedStatus: PropTypes.bool,
      label: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      copiedStatus: PropTypes.bool,
      label: PropTypes.string,
    }),
    office: PropTypes.shape({
      copiedStatus: PropTypes.bool,
      label: PropTypes.string,
    }),
    pager: PropTypes.shape({
      copiedStatus: PropTypes.bool,
      label: PropTypes.string,
    }),
  }),
  handleCopyClick: PropTypes.func,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  pagerNumber: PropTypes.string,
  onCallFormattedDate: PropTypes.string,
  nextOnCallFormattedDate: PropTypes.string,
  notes: PropTypes.string,
  handlePhoneClick: PropTypes.func,
  featureFlags: PropTypes.object,
  handleEmailClick: PropTypes.func,
  c2cDependencies: PropTypes.any
};

/* Export component */
export default DetailedInfo;
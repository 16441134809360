import React, {useState } from 'react';
import { useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import LanguageList from '../LanguageList/LanguageList';
import SearchInterpreter from '../SearchInterpreter/SearchInterpreter';
import { INTERPRETER_VIDEO, INTERPRETER_AUDIO, SELECT_INTERPRETER_VIDEO } from "../../constants/interpreter.constants";
import DialPadContainer from '../DialPadContainer/DialPadContainer';
import { CONTEXT_ADD_INTERPRETER } from '../../constants/dialpad.constants';
import { getRadioButtonsForInterpreter } from "../../helper/commonUtils";
import { STORE_NAMES } from "../../constants/store.constants";
import ServiceAreaList from "../ServiceAreaList/ServiceAreaList";
import { VISIT_TYPES } from "../../constants/app.constants"
import  AslLanguage   from "../AslLanguage/AslLanguage";
const AddInterpreter = () => {
  
  const { tabSettings } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const { appointmentRegion: region, appointmentVisitType: visitType } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);
  const { RadioButtons, interpreterDefaultValue } = getRadioButtonsForInterpreter(tabSettings);
  
  const [interpreterType, setInterpreterType] = useState(interpreterDefaultValue);
  const onInterpreterTypeSelect = (event) => setInterpreterType(event.target.value);
  const isGvVisitType = visitType === VISIT_TYPES.GV;
  return (

    <div className="add-interpreter-container">
      {!(isGvVisitType) && 
      <Form>
        <div key="default-radio" className="p-3">
          {RadioButtons.map((radioButton, index) => {
            const btnIndex = `radio-${index}`;
            return (
              <Form.Check
                key={btnIndex}
                type="radio"
                label={radioButton.label}
                name={radioButton.name}
                value={radioButton.value}
                defaultChecked={radioButton.checked}
                onChange={onInterpreterTypeSelect}
                inline
              />
          )})}
        </div>
      </Form>
      }

      {interpreterType===INTERPRETER_VIDEO && !isGvVisitType && 
        <>
          <p>{SELECT_INTERPRETER_VIDEO}</p>
          <SearchInterpreter interpreterType={INTERPRETER_VIDEO}></SearchInterpreter>
          <LanguageList interpreterType={INTERPRETER_VIDEO}></LanguageList>
        </>
      }

      {interpreterType===INTERPRETER_AUDIO && !isGvVisitType && 
          <>
            <ServiceAreaList interpreterType={INTERPRETER_AUDIO}></ServiceAreaList>
            <DialPadContainer context={CONTEXT_ADD_INTERPRETER}></DialPadContainer>
          </>
      }
      { isGvVisitType && 
      <>
     <AslLanguage></AslLanguage>
     <LanguageList interpreterType={INTERPRETER_VIDEO}></LanguageList>
      </>  
      } 
    </div>
  );
};

export default AddInterpreter;
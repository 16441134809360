const ACTION_TYPES = {
	SET_UID: "SET_UID",
	INITIALIZE_APP_SETUP: "INITIALIZE_APP_SETUP",
	FETCH_PING_TOKEN_DETAILS_SUCCESS: "FETCH_PING_TOKEN_DETAILS_SUCCESS",
	FETCH_APP_SETTINGS_SUCCESS: "FETCH_APP_SETTINGS_SUCCESS",
	FETCH_APP_REGION_SETTINGS_SUCCESS: "FETCH_APP_REGION_SETTINGS_SUCCESS",
	SET_TAB_SETTINGS: "SET_TAB_SETTINGS",
	SET_CURRENT_TAB_CONTEXT: "SET_CURRENT_TAB_CONTEXT",
	ENABLE_INVITE_BUTTON: "ENABLE_INVITE_BUTTON",
	SET_INTERPRETER_TYPE: "SET_INTERPRETER_TYPE",
	SET_INTERPRETER_LANGUAGE: "SET_INTERPRETER_LANGUAGE",
	SET_INTERPRETER_LANGUAGE_LIST: "SET_INTERPRETER_LANGUAGE_LIST",
	SET_INTERPRETER_DISPLAY_LIST: "SET_INTERPRETER_DISPLAY_LIST",
	SET_INTERPRETER_SERVICE_AREA: "SET_INTERPRETER_SERVICE_AREA",
	SET_INTERPRETER_SEARCH_QUERY: "SET_INTERPRETER_SEARCH_QUERY",
	SET_SERVICE_AREA_IS_LOADING: "SET_SERVICE_AREA_IS_LOADING",
	SET_GUEST_INVITE_TYPE: "SET_GUEST_INVITE_TYPE",
	SET_GUEST_INVITE_EMAIL: "SET_GUEST_INVITE_EMAIL",
	SET_GUEST_INVITE_MOBILE: "SET_GUEST_INVITE_MOBILE",
	INVITE_BUTTON_CLICKED: "INVITE_BUTTON_CLICKED",
	INVITE_BUTTON_SNOW_INCIDENT: "INVITE_BUTTON_SNOW_INCIDENT",
	INVITE_BUTTON_SNOW_STATUS: "INVITE_BUTTON_SNOW_STATUS",
	SET_DIALPAD_DETAILS: "SET_DIALPAD_DETAILS",
	SET_DIALPAD_CLEAR_BTN_HIDDEN_STATUS: "SET_DIALPAD_CLEAR_BTN_HIDDEN_STATUS",
	SET_ZOOM_CLIENT_DETAILS: "SET_ZOOM_CLIENT_DETAILS",
	SET_DIALPAD_DISABLE_PHONENUMBER: "SET_DIALPAD_DISABLE_PHONENUMBER",
	SET_ERROR_HANDLER: "SET_ERROR_HANDLER",
	REFRESH_TOKEN: "REFRESH_TOKEN",
	REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",
	REFRESH_TOKEN_FAILURE_WITH_RETRY: "REFRESH_TOKEN_FAILURE_WITH_RETRY",
	DISABLE_SERVICE_AREA: "DISABLE_SERVICE_AREA",
	DISABLE_LANGUAGE_LIST: "DISABLE_LANGUAGE_LIST",
	APP_INITIALIZATION_FAILURE: "APP_INITIALIZATION_FAILURE",
	APP_UNAVAILABLE: "APP_UNAVAILABLE",
	INTEGRATED_UNAVAILABLE: "INTEGRATED_UNAVAILABLE",
	NON_INTEGRATED_UNAVAILABLE: "NON_INTEGRATED_UNAVAILABLE",
	DIRECTORY_UNAVAILABLE: "DIRECTORY_UNAVAILABLE",
	SET_MULTI_GUEST_INVITEES: "SET_MULTI_GUEST_INVITEES",
	SET_MULTI_GUEST_PATIENT_INVITEES: "SET_MULTI_GUEST_PATIENT_INVITEES",
	FETCH_SUPPORT_INFO: "FETCH_SUPPORT_INFO",
	SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
	SET_CLINICIAN_DETAILS: "SET_CLINICIAN_DETAILS",
	SET_START_END_DATE: "SET_START_END_DATE",
	SET_GROUP_CLASS_LIST: "SET_GROUP_CLASS_LIST",
	FETCH_DIRECTORY_CONFIG_SUCCESS: "FETCH_DIRECTORY_CONFIG_SUCCESS",
	FETCH_SNOW_ENABLED: "FETCH_SNOW_ENABLED",
	FETCH_SUPPORT_CURRENT_TAB: "FETCH_SUPPORT_CURRENT_TAB",
	FETCH_SUPPORT_CATEGORY: "FETCH_SUPPORT_CATEGORY",
	SUPPORT_COPY_NUMBER: "SUPPORT_COPY_NUMBER",
	SUPPORT_SELECTED_SNOW_PHONE_NUMBER: "SUPPORT_SELECTED_SNOW_PHONE_NUMBER",
	SUPPORT_SELECTED_SNOW_CATEGORY: "SUPPORT_SELECTED_SNOW_CATEGORY",
	SUPPORT_SELECTED_SNOW_DESCRIPTION: "SUPPORT_SELECTED_SNOW_DESCRIPTION",
	RESET_SNOW_FORM: "RESET_SNOW_FORM",
	SET_MULTI_INVITE_TRACKING_DETAILS: "SET_MULTI_INVITE_TRACKING_DETAILS",
	CLEAR_MULTI_INVITE_TRACKING_DETAILS: "CLEAR_MULTI_INVITE_TRACKING_DETAILS",
	LAST_SUCCESSFUL_MULTI_INVITE_TRACKING_DETAILS: "LAST_SUCCESSFUL_MULTI_INVITE_TRACKING_DETAILS",
	DISPLAY_FAILED_NOTIFICATIONS_MODAL: "DISPLAY_FAILED_NOTIFICATIONS_MODAL",
	DISPLAY_SPINNER_MODAL: "DISPLAY_SPINNER_MODAL",
	SET_PROVIDER_DETAILS: "SET_PROVIDER_DETAILS",
	FETCH_REGION_CODE_MAP_DETAILS : "FETCH_REGION_CODE_MAP_DETAILS",
	/* On Call feature */
	SET_ONCALL_DETAILS: "SET_ONCALL_DETAILS",
	UPDATE_FAVORITE_TOGGLE_CLINCIAN: 'UPDATE_FAVORITE_TOGGLE_CLINCIAN',
	SHOW_SYSTEM_ERROR_MODAL: "SHOW_SYSTEM_ERROR_MODAL",
	UPDATE_DEPARTMENTLIST: "UPDATE_DEPARTMENTLIST",
	CREATE_DEPARTMENTLIST: "CREATE_DEPARTMENTLIST",
	UPDATE_PAGESETTINGS: "UPDATE_PAGESETTINGS",
	CREATE_MEDICALCENTERSLIST: "CREATE_MEDICALCENTERSLIST",
	UPDATE_SELECTED_DEPARTMENTNAME: "UPDATE_SELECTED_DEPARTMENTNAME",
	UPDATE_PROVIDERLIST: "UPDATE_PROVIDERLIST",
	UPDATE_SELECTED_PROVIDERLIST: "UPDATE_SELECTED_PROVIDERLIST",
	UPDATE_CLINICIANLIST: "UPDATE_CLINICIANLIST",
	UPDATE_FAVORITE_STATUS: "UPDATE_FAVORITE_STATUS",
	UPDATE_SELECTED_CLINICIANLIST: "UPDATE_SELECTED_CLINICIANLIST",
	UPDATE_MOBILEVIEW_MATRIX: "UPDATE_MOBILEVIEW_MATRIX",
	SET_DEPARTMENT_LOADER: "SET_DEPARTMENT_LOADER",
	CREATE_CLINICIANLIST: "CREATE_CLINICIANLIST",
	SET_CLINICIAN_LOADER: "SET_CLINICIAN_LOADER",
	UPDATE_SEARCH_CLINICIANLIST:"UPDATE_SEARCH_CLINICIANLIST",
	UPDATE_FILTER_RESET_ON_TOGGLE: 'UPDATE_FILTER_RESET_ON_TOGGLE',
	SET_PATIENT_ABRIDGE_CONSENT: "SET_PATIENT_ABRIDGE_CONSENT",
	SET_MENU_ITEM_DISABLE: "SET_MENU_ITEM_DISABLE"
};

const ACTIONS_TO_WATCH_FOR_TO_ALLOW_INVITE = [
	ACTION_TYPES.SET_INTERPRETER_LANGUAGE,
	ACTION_TYPES.SET_INTERPRETER_SERVICE_AREA,
	ACTION_TYPES.SET_GUEST_INVITE_EMAIL,
	ACTION_TYPES.SET_GUEST_INVITE_MOBILE
];

const ACTIONS_TO_WATCH_FOR_TO_RESET_INTERPRETER_OR_GUEST = [
	ACTION_TYPES.SET_CURRENT_TAB_CONTEXT,
	ACTION_TYPES.SET_INTERPRETER_TYPE
];

const STORE_NAMES = {
	APP: 'APP',
	TABS: 'TABS',
	INVITE_BUTTON: 'INVITE_BUTTON',
	INTERPRETER: 'INTERPRETER',
	GUEST: 'GUEST',
	DIALPAD: 'DIALPAD',
	ZOOM_CLIENT: 'ZOOM_CLIENT',
	APPOINTMENT_DETAILS: 'APPOINTMENT_DETAILS',
	INTERPRETER_AUDIO_INFO: 'INTERPRETER_AUDIO_INFO',
	ERROR_HANDLER: 'ERROR_HANDLER',
	SESSION_INTERVAL_DETAILS: 'SESSION_INTERVAL_DETAILS',
	ONCALL_DIRECTORY: 'ONCALL_DIRECTORY',
	CLINICIAN_DIRECTORY: 'CLINICIAN_DIRECTORY',
	ONCALL_DIRECTORY_DEPARTMENTLIST: 'ONCALL_DIRECTORY_DEPARTMENTLIST',
	ONCALL_DIRECTORY_MEDICALCENTERLIST: 'ONCALL_DIRECTORY_MEDICALCENTERLIST',
	ONCALL_DIRECTORY_PROVIDERLIST: 'ONCALL_DIRECTORY_PROVIDERLIST',
	CLINICIAN_DIRECTORY_CLINICIANLIST: 'CLINICIAN_DIRECTORY_CLINICIANLIST',
	DIRECTORY_CONFIG: 'DIRECTORY_CONFIG',
	SUPPORT_TAB_INFO: 'SUPPORT',
	REPORTS: 'REPORTS',
	ABRIDGE: 'ABRIDGE'
}

export {
	ACTION_TYPES,
	ACTIONS_TO_WATCH_FOR_TO_ALLOW_INVITE,
	ACTIONS_TO_WATCH_FOR_TO_RESET_INTERPRETER_OR_GUEST,
	STORE_NAMES
};
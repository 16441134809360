import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button/Button';
import PadKey from './PadKey/PadKey';
import { STORE_NAMES } from '../../../constants/store.constants';
import { debounce, truncateSpecialChar, getFormattedPhoneNumber, mapUnformattedCursorPosition, mapformattedCursorPosition } from '../../../helper/commonHelper';
import { isPhoneNumberValid, removeNonNumericCharacters } from '../../../helper/dialPadHelper';
import {
    PAD_KEYS,
    LONG_PRESS_DELAY,
    DEFAULT_PAD_OPTIONS,
    DEFAULT_PAD_SETTINGS,
    ALLOWED_CHARS_FOR_DELETION,
    CONTEXT_ADD_INTERPRETER,
    CONTEXT_ADD_GUEST
} from '../../../constants/dialpad.constants';
import { applyDesktopClass } from '../../../helper/global.utilities';
import { APP_DEVICE_TYPE } from "../../../constants/zoomApp.constants";

const DialPadControl = ({ options = {}, settings = {}, emitDetails }) => {

    const {
        inputType = DEFAULT_PAD_OPTIONS.inputType,
        value = DEFAULT_PAD_OPTIONS.value,
        disableInput = DEFAULT_PAD_OPTIONS.value,
        minLength = DEFAULT_PAD_OPTIONS.minLength,
        autoFocus = DEFAULT_PAD_OPTIONS.autoFocus
    } = options;

    const {
        hideClearButton = DEFAULT_PAD_SETTINGS.hideClearButton,
        disableClearButton = DEFAULT_PAD_SETTINGS.disableClearButton
    } = settings;
    const { disablePhoneNumber } = useSelector(state => state?.[STORE_NAMES.DIALPAD]);
    const { contact_number } = useSelector(state => state?.[STORE_NAMES.INTERPRETER_AUDIO_INFO]);
    const { deviceType } = useSelector((state) => state?.[STORE_NAMES.ZOOM_CLIENT]);
    const  disableVirtualKeyboard  = (APP_DEVICE_TYPE[deviceType] === APP_DEVICE_TYPE.mobile);
    const [inputValue, setInputValue] = useState(value);
    const [isInputValid, setValidityStatus] = useState(options.isInputValid);
    const [isLongPress, setIsLongPress] = useState(false);
    const longPressTimerRef = useRef();
    const inputRef = useRef(null);
    const [nextCaretPosition, setNextCaretPosition] = useState(null);
    const { currentTabContext } = useSelector(state => state?.[STORE_NAMES.TABS]);
    const handleInputChange = () => updateCursorPositionAndInputValue({ eventType: "inputChange" });
    const handlePadKeyClick = (event) => updateCursorPositionAndInputValue({ eventType: "padKeyClick", data: event });

    const handleClearButtonClick = () => {
        if (isLongPress.current) {
            return;
        }
        updateCursorPositionAndInputValue({ eventType: "clearButtonClick" });
    };

    const handleInputCursorPosition = () => {
        if (!disableVirtualKeyboard) {
            inputRef?.current?.focus();
        }
        if (nextCaretPosition !== null) {
            inputRef.current.setSelectionRange(nextCaretPosition, nextCaretPosition);
        }
        if (inputValue.length === 0) {
            setNextCaretPosition(null);
        }
    };

    const handleClearButtonMouseDown = () => startLongPressTimer();
    const handleClearButtonMouseUp = () => { clearTimeout(longPressTimerRef.current); longPressTimerRef.current = null; };
    const handleClearButtonTouchStart = () => startLongPressTimer();
    const handleClearButtonTouchEnd = () => { clearTimeout(longPressTimerRef.current); longPressTimerRef.current = null; };

    const processPhoneNumberInput = (event) => {
        const truncatedvalue = truncateSpecialChar(event);
        const formattedPhoneNumber = getFormattedPhoneNumber(truncatedvalue);
        setInputValue(formattedPhoneNumber);
        const inputValidityStatus = isPhoneNumberValid(formattedPhoneNumber);
        setValidityStatus(inputValidityStatus);
    };

    const startLongPressTimer = () => {
        setIsLongPress(false);
        longPressTimerRef.current = setTimeout(() => {
            setIsLongPress(true);
            processPhoneNumberInput("");
        }, LONG_PRESS_DELAY);
    };

    const debounceCursorPosition = useCallback(debounce((nextValue) => inputRef.current?.setSelectionRange(nextValue, nextValue), 50), []);


    const updateCursorPositionAndInputValue = (details) => {
        const { eventType, data = null } = details;
        const currentCursorPosition = inputRef.current?.selectionStart;
        const currentInputValue = inputRef.current?.value;
        let newCursorPosition = mapUnformattedCursorPosition(currentInputValue, inputRef.current?.selectionStart);
        if (eventType === "inputChange") {
            if(!disableVirtualKeyboard) {
                if (currentInputValue.length === 10 && truncateSpecialChar(currentInputValue).length === 10) {
                    newCursorPosition = mapformattedCursorPosition(currentCursorPosition);
                    setNextCaretPosition(newCursorPosition);
                } else if (truncateSpecialChar(currentInputValue).length === 10) {
                    newCursorPosition = currentCursorPosition;
                    debounceCursorPosition(newCursorPosition + 3);
                } else {
                    setNextCaretPosition(newCursorPosition);
                }
            }
            processPhoneNumberInput(currentInputValue);
        }
        else if (eventType === "padKeyClick") {
            const firstSplitValue = currentInputValue.slice(0, currentCursorPosition);
            const secondSplitValue = currentInputValue.slice(currentCursorPosition);
            const totalValue = firstSplitValue + data + secondSplitValue
            if (!disableVirtualKeyboard) {
                if ((totalValue).length === 10 && truncateSpecialChar(totalValue).length === 10) {
                    newCursorPosition = mapformattedCursorPosition(currentCursorPosition);
                    setNextCaretPosition(newCursorPosition + 1);
                } else {
                    setNextCaretPosition(currentCursorPosition + 1);
                }
            }
            processPhoneNumberInput(totalValue);
        }
        else if (eventType === "clearButtonClick" && currentInputValue.length > 0 && currentCursorPosition > 0) {
            const charIndex = currentCursorPosition - 1;
            const valueAtCursorPosition = currentInputValue.charAt(charIndex);
            if (valueAtCursorPosition !== "" &&
                valueAtCursorPosition !== " " &&
                (!isNaN(valueAtCursorPosition) || ALLOWED_CHARS_FOR_DELETION.includes(valueAtCursorPosition))) {
                    if(!disableVirtualKeyboard) {
                        setNextCaretPosition(newCursorPosition - 1);
                    }
                processPhoneNumberInput(currentInputValue.slice(0, charIndex) + currentInputValue.slice(charIndex + 1));
            } else {
                inputRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (currentTabContext === CONTEXT_ADD_INTERPRETER && contact_number && typeof contact_number === 'string') {
            options.disableInput = disablePhoneNumber ? disablePhoneNumber : false
            if(!disablePhoneNumber) {
                processPhoneNumberInput(contact_number);
            }
        }
        if(currentTabContext === CONTEXT_ADD_GUEST) {
            options.disableInput = disablePhoneNumber;
        }
    }, [disablePhoneNumber]);

    useEffect(() => {
        const inputValueNumbersOnly = inputValue ? removeNonNumericCharacters(inputValue) : null;
        const details = { isInputValid: isInputValid, inputValue: inputValue, inputValueNumbersOnly: inputValueNumbersOnly };
        emitDetails(details);
        handleInputCursorPosition();
    }, [inputValue]);

    return (
        <>
            <div className="vve-phonenumber-wrapper">
                <input name="phoneNumber"
                    id="phoneNumber"
                    ref={inputRef}
                    type={inputType}
                    value={inputValue}
                    disabled={disableInput}
                    autoFocus={(!disableVirtualKeyboard) ? autoFocus : false}
                    minLength={minLength}
                    onChange={handleInputChange} />
                {hideClearButton !== true &&
                    <Button disabled={disableClearButton}
                        onClick={handleClearButtonClick}
                        onMouseDown={handleClearButtonMouseDown}
                        onMouseUp={handleClearButtonMouseUp}
                        onTouchStart={handleClearButtonTouchStart}
                        onTouchEnd={handleClearButtonTouchEnd}
                        class="vve-clear-btn">
                        {/* <span className="vve-backspace-icon">&times;</span> */}
                    </Button>
                }
            </div>
            <div className={`vve-keypad-wrapper ${applyDesktopClass(deviceType, 'vve-smalldesk-keypad-wrapper')}`}>
                {PAD_KEYS.map((padKey, index) => {
                    const itemIndex = `vve-keypad-${index}`;
                    return <PadKey key={itemIndex} padKey={padKey} onClick={handlePadKeyClick} />
                })}
            </div>
        </>
    )

};

DialPadControl.propTypes = {
    options: PropTypes.any,
    settings: PropTypes.any,
    emitDetails: PropTypes.any
};

export default DialPadControl;
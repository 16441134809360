import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InviteButton from '../InviteButton/InviteButton';
import { intialiseAppSetup } from '../../store/actions/AppAction';
import { STORE_NAMES } from '../../constants/store.constants';
import InviteModal from '../InviteModal/InviteModal';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import RefreshToken from '../RefreshToken/RefreshToken';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RegionList from '../RegionList/RegionList';
import { PARAM_KEY_UID } from '../../constants/app.constants';
import { ADD_SUPPORT_KEY, INVISIT_ABRIDGE_KEY } from '../../constants/tab.constants';
import InVisitMenu from '../InVisitMenu/InVisitMenu';
import AbridgeConsentStatusRefreshButton from '../AddAbridge/AbridgeConsentStatusRefreshButton';

const Main = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const appointmentDetails = useSelector(state => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);
  const navigate = useNavigate();
  const { currentTabContext } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const { regionFeatureEnabled } = useSelector(state => state?.[STORE_NAMES.APP]);
  const { currentSupportTab } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
  const { appConfig: { menuItems: menuItemsConfig } } = useSelector(state => state?.[STORE_NAMES.APP]);

  useEffect(() => {
    const uidParam = searchParams.get(PARAM_KEY_UID);
    if(uidParam!==null && uidParam.length > 0) {
      dispatch(intialiseAppSetup(uidParam));
    }
    else {
      console.log("uid not available");
      navigate("/error");
    }
  }, []);

  if(regionFeatureEnabled && !(appointmentDetails && appointmentDetails.appointmentRegion) ) {
    return <div className="vve-main-container container-fluid">
       <RegionList />
     </div>
   }

  if (appointmentDetails && menuItemsConfig) {
    return (
      <div className="vve-main-container container-fluid">
        <InVisitMenu />
        {(currentTabContext !== ADD_SUPPORT_KEY || currentSupportTab === 'SNOW') && <hr className="row vve-main-ruler"/>}
        {((currentTabContext !== INVISIT_ABRIDGE_KEY && currentTabContext !== ADD_SUPPORT_KEY) || (currentSupportTab === 'SNOW' && currentTabContext !== INVISIT_ABRIDGE_KEY)) && <InviteButton />}
        {(currentTabContext === INVISIT_ABRIDGE_KEY) && <AbridgeConsentStatusRefreshButton />}
        <InviteModal />
        <ErrorHandler />
        <RefreshToken />
      </div>
    );
  }
  else{
    return (<ErrorHandler/>);
  }
};

export default Main;
import React from 'react';
import { ASL_CONTENT_SELECT_ONE,ASL_CONTENT_INVITE,ASL_CONTENT_SELECT_TWO,ASL_CONTENT_GROUP_VISIT } from "../../constants/aslLanguage.constant";


const AslLanguage = () => {

    return (<div className='vve-asl-language-text-container'>
        <p className='vve-asl-text vve-medium'>{ASL_CONTENT_SELECT_ONE}<b>{ASL_CONTENT_INVITE}</b>{ASL_CONTENT_SELECT_TWO}</p>
        <p className='vve-asl-text vve-medium'>{ASL_CONTENT_GROUP_VISIT}</p>
        
    </div>)
}

export default AslLanguage;

import { ACTION_TYPES } from "../../constants/store.constants";

const CreateClinicianList = (payload) => {
    return {
        type: ACTION_TYPES.CREATE_CLINICIANLIST,
        payload: payload
    };
}
const UpdatePageSettings = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_PAGESETTINGS,
        payload: payload
    };
}

const UpdateFavoriteStatus = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_FAVORITE_STATUS,
        payload: payload,
    };
};
const UpdateClinicianList = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_CLINICIANLIST,
        payload: payload,
    };
};
const UpdateSelectedClinician = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_SELECTED_CLINICIANLIST,
        payload: payload,
    };
};
const UpdateFavoriteClinician = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_FAVORITE_TOGGLE_CLINCIAN,
        payload: payload,
    };
};
const setClinicianLoader = (payload) => {
    return {
        type: ACTION_TYPES.SET_CLINICIAN_LOADER,
        payload: payload
    };
};

const UpdateFilterResetOnToggle = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_FILTER_RESET_ON_TOGGLE,
        payload: payload,
    };
};
const ShowSystemErrorModal = (payload) => {
    return {
        type: ACTION_TYPES.SHOW_SYSTEM_ERROR_MODAL,
        payload: payload
    };
};

export {
    CreateClinicianList,
    UpdatePageSettings,
    UpdateClinicianList,
    UpdateSelectedClinician,
    setClinicianLoader,
    UpdateFavoriteClinician,
    UpdateFavoriteStatus,
    UpdateFilterResetOnToggle,
    ShowSystemErrorModal
};

import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import { STORE_NAMES } from "../../constants/store.constants";
import ZoomAppClient from "../../helper/zoomAppClient";
import { FailedReportDownloadModal } from "./FailedReportDownloadModal";
import { PARAM_KEY_AUTH_TOKEN } from "../../constants/service.constants";
import { PARAM_KEY_MEETING_UUID, PARAM_KEY_ATTENDEE_REPORT_FILE } from "../../constants/app.constants";
import { fetchAttendeeReportDownloadToken } from "../../service/auth.service";
import dayjs from "dayjs";

const DownloadReportButton = (downloadReportProps) => {
    const { btnClass, btnLabel = null, zoomMeetingId, zoomMeetingUuid, title, dateTime } = downloadReportProps;
    const { attendeeReportDetails: { hideDownloadFeature } } = useSelector(state => state?.[STORE_NAMES.DIRECTORY_CONFIG]);
    const [displayFailedReportDownloadModal, setDisplayFailedReportDownloadModal] = useState(false);

    const prepareExternalReportPageUrl = ({authToken, reportFileName}) => {
        const paramList = new URLSearchParams({
            [PARAM_KEY_AUTH_TOKEN]: encodeURIComponent(authToken),
            [PARAM_KEY_MEETING_UUID]: encodeURIComponent(zoomMeetingUuid),
            [PARAM_KEY_ATTENDEE_REPORT_FILE]: encodeURIComponent(reportFileName)
        });
        const baseUrl = location.origin;
        const generateReportPagePath = "download-attendee-report";
        return `${baseUrl}/${generateReportPagePath}?${paramList.toString()}`;
    };

    const getReportFileName = () => {
        const formattedTitle = title.replace(/\s+/g,"_");
        const formattedDateTime = dayjs(dateTime).format("MM-DD-YYYY");
        return `${formattedDateTime}_${formattedTitle}_${zoomMeetingId}.xlsx`;
    };

    const initiateReportDownloadProcess = (authToken) => {
        const reportFileName = getReportFileName();
        const reportDownloadExternalUrl = prepareExternalReportPageUrl({ authToken, reportFileName });
        ZoomAppClient.openExternalUrl(reportDownloadExternalUrl);
    };

    const parseAuthTokenResponse = ({ successResponse = {}, exception = null }) => {
        const { access_token: authToken = null } = successResponse;
        if(authToken && authToken.length > 0) {
            initiateReportDownloadProcess(authToken);
        }

        if(exception || !authToken) {
            setDisplayFailedReportDownloadModal(true);
        }
    };

    const getAuthToken = async () => {
        let responseBody = {};
        try {
            responseBody.successResponse = await fetchAttendeeReportDownloadToken();
        }
        catch(error) {
            responseBody.exception = error;
        }
        finally {
            parseAuthTokenResponse(responseBody);
        }
    };

    const handleFailedReportDownloadModalClose = () => {
        setDisplayFailedReportDownloadModal(false);
    };

    const handleDownloadClick = (e) => {
        getAuthToken();
        e.stopPropagation()
    };

    return <>

        { hideDownloadFeature === false &&
            <Button class={btnClass} onClick={handleDownloadClick}>{ btnLabel }</Button>
        }

        { displayFailedReportDownloadModal &&
            <FailedReportDownloadModal onCloseHandler={handleFailedReportDownloadModalClose} />
        }

    </>
};
  
export default DownloadReportButton;
import axios from "axios";
import { DEFAULT_HTTP_OPTIONS, HEADER_KEY_NAME } from "../constants/service.constants";

class ApiClient {

	static zoomAppBffInstance = null;
	static clinicianAppBffInstance = null;
	static reportDownloadInstance = null;

	static {
		this.zoomAppBffInstance = axios.create(DEFAULT_HTTP_OPTIONS);
		this.zoomAppBffInstance.interceptors?.response.use((response) => response?.data, (error) => Promise.reject(error));

		this.clinicianAppBffInstance = axios.create(DEFAULT_HTTP_OPTIONS);
		this.clinicianAppBffInstance.interceptors?.response.use((response) => response?.data, (error) => Promise.reject(error));

		const REPORT_DOWNLOAD_HTTP_OPTIONS = { ...DEFAULT_HTTP_OPTIONS, withCredentials: false };
		this.reportDownloadInstance = axios.create(REPORT_DOWNLOAD_HTTP_OPTIONS);
		this.reportDownloadInstance.interceptors?.response.use((response) => response?.data, (error) => Promise.reject(error));

		// This section reads server props passed from /conference backend route and sets al relevant info
		const SERVER_PROPS = JSON.parse(document.getElementById('serverProps') && document.getElementById('serverProps').innerHTML || JSON.stringify({}));
		const { 
			envlbl = null, 
			url: zoomAppBffBaseUrl = null, 
			clinicianUrl: clinicianDirectoryBffBaseURL = null, 
			reportDownloadBaseUrl = null 
		} = SERVER_PROPS;
		if (envlbl && envlbl !== "none") {
			this.zoomAppBffInstance.defaults.headers[HEADER_KEY_NAME.envlbl] = envlbl;
			this.clinicianAppBffInstance.defaults.headers[HEADER_KEY_NAME.envlbl] = envlbl;
			this.reportDownloadInstance.defaults.headers[HEADER_KEY_NAME.envlbl] = envlbl;
		}
		if (clinicianDirectoryBffBaseURL && clinicianDirectoryBffBaseURL.length > 0) {
			this.clinicianAppBffInstance.defaults.baseURL = clinicianDirectoryBffBaseURL;
		}
		if (zoomAppBffBaseUrl && zoomAppBffBaseUrl.length > 0) {
			this.zoomAppBffInstance.defaults.baseURL = zoomAppBffBaseUrl;
		}
		if (reportDownloadBaseUrl && reportDownloadBaseUrl.length > 0) {
			this.reportDownloadInstance.defaults.baseURL = reportDownloadBaseUrl;
		}
	}

	static configureClinicianDirectoryBffInstance(providerNuid) {
		if (providerNuid) {
			this.clinicianAppBffInstance.defaults.headers[HEADER_KEY_NAME.requestUser] = providerNuid.toUpperCase();
			this.reportDownloadInstance.defaults.headers[HEADER_KEY_NAME.requestUser] = providerNuid.toUpperCase();
		}
	}

	static getZoomAppBffInstance() {
		return this.zoomAppBffInstance;
	}

	static getClincianAppBffInstance() {
		return this.clinicianAppBffInstance;
	}

	static getReportDownloadInstance() {
		return this.reportDownloadInstance;
	}
}

export default ApiClient;

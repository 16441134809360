import SDK from "@zoom/appssdk";
import { APP_CAPABILITIES, DIRECTORY_APP_CAPABILITIES, APP_DEVICE_TYPE, APP_POPOUT_SIZE } from "../constants/zoomApp.constants";
import { getMockClientDetails } from "../mock/zoomAppClient.mock";
import { parseUserAgent } from './commonHelper';
class ZoomAppClient {

	static IS_ZOOM_CLIENT_SUPPORTED = true;
	static ZOOM_APP_CONFIG_RESPONSE = null;
	static DEVICE_TYPE = null

	static async configureApp(isDirectory) {
		try {
			const capabilities = isDirectory === true ? DIRECTORY_APP_CAPABILITIES : APP_CAPABILITIES;
			this.ZOOM_APP_CONFIG_RESPONSE = await SDK.config({
				popoutSize: APP_POPOUT_SIZE,
				capabilities: capabilities
			});
			this.DEVICE_TYPE = APP_DEVICE_TYPE[this.ZOOM_APP_CONFIG_RESPONSE.product];
			console.log("configureApp response", this.ZOOM_APP_CONFIG_RESPONSE);
		}
		catch (error) {
			console.log("configureApp error", error);
			this.IS_ZOOM_CLIENT_SUPPORTED = false;
		}
		return this.IS_ZOOM_CLIENT_SUPPORTED;
	}

	static async getClientDetails(isDirectory = false) {
		if (!this.IS_ZOOM_CLIENT_SUPPORTED) {
			return getMockClientDetails();
		}

		const clientDetails = {
			deviceType: this.DEVICE_TYPE
		};

		if (isDirectory === true) {
			return clientDetails;
		}
		const meetingContextResponse = await SDK.getMeetingContext() || null; // { meetingTopic: string; meetingID: string }
		const userContextResponse = await SDK.getUserContext() || null; // { status: "unauthenticated" | "authenticated" | "authorized"; screenName: string; participantId: string; participantUUID: string; role: string }
		const meetingJoinUrlResponse = await SDK.getMeetingJoinUrl() || null; // { joinUrl: string }
		const meetingUUIDResponse = await SDK.getMeetingUUID() || null; // { meetingUUID: string; parentUUID?: string }
		const browserOSINFO = parseUserAgent(this.ZOOM_APP_CONFIG_RESPONSE);
		return {
			meetingID: meetingContextResponse?.meetingID || null,
			meetingJoinUrl: meetingJoinUrlResponse?.joinUrl || null,
			meetingUUID: meetingUUIDResponse?.meetingUUID || null,
			userContext: userContextResponse || null,
			browserOSInfo: browserOSINFO,
			...clientDetails
		};
	}

	static async openExternalUrl(externalUrl) {
		if (this.IS_ZOOM_CLIENT_SUPPORTED && this.DEVICE_TYPE) {
			await SDK.openUrl({ url: externalUrl });
		}
		else {
			window.open(externalUrl, '_blank');
		}
	}

	static async MakePhoneCall(number) {
		if (this.IS_ZOOM_CLIENT_SUPPORTED) {
			await SDK.makePhoneCall({ phoneNumber: number, callerId: 'zoom call', autoDial: true });
		}
	}
}

export default ZoomAppClient;

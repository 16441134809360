import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	clinicianFilterDetails: {},
	directoryRegion: null,
	hideOncall: null,
	attendeeReportDetails: {
		hideDownloadFeature: false
	},
	featureFlags: {
		oneClickEmail: true,
		oneClickPhone: true,
		favorites: true
	},
	clinicianToClinicianInvite: {
		enableC2CInvite: false,
		dnpRegionMap: null
	}
};

const DirectoryConfigReducer = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ACTION_TYPES.FETCH_DIRECTORY_CONFIG_SUCCESS:
			const { appointmentRegion, directoryConfig } = payload;
			const {
				features: {
					directory: {
						clinicianFilterDetails,
						regionMap,
						hideOncall
					},
					featureFlags: {
						oneClickEmail,
						oneClickPhone,
						favorites,
						attendeeReport,
						c2c
					}
				}
			} = directoryConfig;
			const directoryRegion = regionMap[appointmentRegion] ?? appointmentRegion;
			const { dnpRegionMap = {}, displayCameraIcon = {} } = c2c ?? {};
			const enableC2CInvite = displayCameraIcon[directoryRegion] ?? false;
			const clinicianToClinicianInvite = { enableC2CInvite, dnpRegionMap };
			return {
				...state,
				clinicianFilterDetails,
				directoryRegion,
				hideOncall,
				featureFlags: { oneClickEmail, oneClickPhone, favorites },
				attendeeReportDetails: attendeeReport,
				clinicianToClinicianInvite
			};
		default:
			return state;
	}
};

export default DirectoryConfigReducer;

import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import GroupClassDetails from "../GroupClassDetails/GroupClassDetails";
import DefaultReportsPage from "../DefaultReportPage/DefaultReportPage"
import GroupClassCard from "../GroupClassCard/GroupClassCard";
import { getGroupClassData, getGroupParticipantData } from '../../service/reports.service';
import { STORE_NAMES } from "../../constants/store.constants";
import { NO_MORE_RECORDS_MESSAGE,FILTER_REPORT_DATE_FORMAT } from "../../constants/report.constant";

const Reports = () => {
  const { provider: { nuid: epicuserId = null } = {}, appointmentRegion="" } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);
  const { startDate, endDate } = useSelector((state) => state?.[STORE_NAMES.REPORTS]);
  const [groupClassList, setGroupClassList] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [participantList, setParticipantList] = useState(null);
  const [noMoreRecordsMessage, setNoMoreRecordsMessage] = useState(null);

  const handleGroupClassCardClick = (item, index) => {
    setParticipantList(null)
    setSelectedCardIndex(index);
    fetchParticipantData(item);
  };

  const fetchParticipantData = async ({ zoomMeetingUuid }) => {
    try {
      const participantData = await getGroupParticipantData(zoomMeetingUuid);
      if(participantData?.error) {
        setParticipantList(null);
        return;
      }
      setParticipantList(participantData);
    } catch (err) {
      setParticipantList(null);
    }
  };

  const fetchGroupClassList = async ({ startDate, endDate }) => {
    try {
      const startDateFormatted = dayjs(startDate).format(FILTER_REPORT_DATE_FORMAT);
      const endDateFormatted = dayjs(endDate).format(FILTER_REPORT_DATE_FORMAT);
      const { videoVisits } = await getGroupClassData(startDateFormatted, endDateFormatted, epicuserId, appointmentRegion);
      if(videoVisits.length > 0) {
        setGroupClassList(videoVisits);
        return;
      }
      setNoMoreRecordsMessage(NO_MORE_RECORDS_MESSAGE.noResponse);
    }
    catch (err) {
      setNoMoreRecordsMessage(NO_MORE_RECORDS_MESSAGE.noResponse);
    }
  }

  const renderCardItems = () => {
    if(Array.isArray(groupClassList) && groupClassList.length > 0) {
      const groupCLassItemList = groupClassList.map((groupClassItem, itemIndex) => {
        const classListItemIndex = `vve-class-item-${itemIndex}`;
        return <div role="button"
                  tabIndex="0"
                  key={classListItemIndex} 
                  onClick={() => handleGroupClassCardClick(groupClassItem, itemIndex)} 
                  className={`${selectedCardIndex === itemIndex ? 'vve-active-card' : ''}`}>
                    <GroupClassCard  key={groupClassItem.visitTypeName} {...groupClassItem} />
              </div>
      });
      return <div className="vve-groupclass-card-list">
              {groupCLassItemList}
              <div className="vve-group-class-message">{NO_MORE_RECORDS_MESSAGE.endOfRecords}</div>
            </div>
    }
    return null;
  };
  
  useEffect(() => {
    setSelectedCardIndex(null);
    setGroupClassList(null);
    setParticipantList(null);
    setNoMoreRecordsMessage(null);
    // startDate and endDate is set by dispatching from inside AttendeeReportFilter
    if(startDate && endDate) {
      fetchGroupClassList({ startDate, endDate });
    }
  }, [startDate, endDate]);

  return (   
    <div className="container-fluid vve-reports-container">
      <div className="row">
        <div className="col-md-4">
          <DefaultReportsPage startDate={startDate} endDate={endDate} />
          { renderCardItems() }
          { noMoreRecordsMessage &&
            <div className="vve-group-class-message">{noMoreRecordsMessage}</div>
          }
        </div>
        <div className="col-md-8">
            { participantList &&
              <GroupClassDetails {...participantList}/>
            }
        </div>
      </div>
    </div>
  );
};
export default Reports;
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "../Image/Image";
import avatar from "../../assets/images/dr-photo-avatar-small.svg";
import { STORE_NAMES } from "../../constants/store.constants";
import { UpdateClinicianList, UpdateSelectedClinician, UpdatePageSettings, UpdateFavoriteStatus, UpdateFavoriteClinician, setClinicianLoader, CreateClinicianList, ShowSystemErrorModal } from "../../store/actions/ClinicianListAction";
import { attachHoverforEllipsedText } from "../../helper/oncallUtils";
import { getClinicianList, getFileteredClinicianList } from "../../service/clinician.service";
import ScrollToBottomDetector from "../ScrollToBottomDetector/ScrollToBottomDetector";
import Loader from "../Loader/Loader";
import unfilledStar from "../../assets/images/unfilledStar.svg";
import filledStar from "../../assets/images/filledStar.svg";
import Toggle from "../Toggle/Toggle";
import IfCondition from "../IfCondition/IfCondition";
import { addFavoriteItem, getFavoriteList, removeFavoriteItem } from "../../service/favorite.service";
import translations from "../../translations";
import DialogBox from '../DialogBox/DialogBox';

const ClinicianList = ({ matrixindex, fwdbtnclick }) => {
  const dispatch = useDispatch();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const {
    clinicianListData = [],
    selectedClinicianData,
    pageConfig,
    loader,
    isFavoriteToggle,
    showSystemErrorModal
  } = useSelector(
    (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]
  );
  const { hideOncall } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);
  const [pageDetails, setPageDetails] = useState(pageConfig);
  const latestPageSettings = useRef(pageDetails);
  const [loaderScroll, setLoaderScroll] = useState(false);
  const [favoriteData, setFavoriteData] = useState([]);
  const { featureFlags } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]
  );

  const fetchFavoriteList = async () => {
    const { region } = pageConfig;
    const param = {
      region: region,
      limit: 200,
      offset: 0,
      orderBy: "lastName",
      order: "ASC",
    }

    const data = await getFavoriteList(param);
    return data;
  }

  const clinicianListClicked = (index, clinician) => {
    setSelectedItemIndex(index);
    dispatch(UpdateSelectedClinician(clinician));
    fwdbtnclick(matrixindex);
  };

  const callBackFnHandleScroll = (pageParams, data) => {
    setLoaderScroll(false);
    dispatch(UpdatePageSettings(pageParams));
    dispatch(UpdateClinicianList(data));
    setPageDetails(pageParams);
  };

  const handleScroll = () => {
    const pageSettings = latestPageSettings.current;
    if (!pageSettings.canStopScrolling) {
      const { region, employeeType, medicalCenter, department } = pageSettings;
      const defaultParams = {
        limit: 200,
        offset: pageSettings.offset + pageSettings.limit,
        searchString: pageSettings.searchString,
        employeeType: employeeType,
        orderBy: "lastName",
        order: "ASC",
        region: region,
        medicalCenter,
        department,
      };
      const params = { region, medicalCenter };
      if (region && employeeType && medicalCenter && department) {
        const deptParams = { ...params, departmentid: department };
        getFileteredClinicianList(
          deptParams,
          defaultParams,
          callBackFnHandleScroll
        );
      } else if (region && employeeType && medicalCenter) {
        getFileteredClinicianList(
          params,
          defaultParams,
          callBackFnHandleScroll
        );
      } else {
        getClinicianList(defaultParams, callBackFnHandleScroll);
      }
      setLoaderScroll(true);
    }
  };

  const callBackFn = (params, data) => {
    dispatch(CreateClinicianList(data));
    dispatch(setClinicianLoader(false))
  }

  const fetchClinicianList = async () => {
    const pageSettings = latestPageSettings.current;
    const { region } = pageSettings;
    dispatch(setClinicianLoader(true))
    dispatch(CreateClinicianList([]));
    const defaultParams = {
      limit: 200,
      offset: 0,
      searchString: "",
      employeeType: "ALL",
      orderBy: "lastName",
      order: "ASC",
      region,
      department: "",
      medicalCenter: ""
    };
    await getClinicianList(defaultParams, callBackFn);
  }

  const getDefaultSrc = (ev) => {
    ev.target.src = avatar;
  };

  const getJobDescriptionTitle = (item) => {
    let value = null;
    if (item.professionalTitle?.trim() !== "") {
      value = `${item.professionalTitle}/${item.jobDescription}`;
    } else {
      value = item.jobDescription;
    }
    return value;
  };

  const handleFavourite = async (action, id) => {
    setLoaderScroll(true);
    const pageSettings = latestPageSettings.current;
    const { region } = pageSettings;
    const params = {
      favoriteIDs: id,
      region: region,
    };
    if (action === 'add') {
      let response = await addFavoriteItem(params);
      setLoaderScroll(false);
      if (response?.error) {
        dispatch(ShowSystemErrorModal(true));
        return;
      }
      dispatch(UpdateFavoriteStatus({ id: id, isFavorite: true }))
      dispatch(UpdateSelectedClinician(null));

    } else {
      let response = await removeFavoriteItem(params);
      setLoaderScroll(false);
      if (response?.error) {
        dispatch(ShowSystemErrorModal(true));
        return;
      }

      dispatch(UpdateFavoriteStatus({ id: id, isFavorite: false }))
      dispatch(UpdateSelectedClinician(null));
      setLoaderScroll(true);
      const data = await fetchFavoriteList();
      setLoaderScroll(false);
      const removerFinalListData = (data.data) ? data.data : data;
      setFavoriteData(removerFinalListData);
      if (removerFinalListData.length === 0) {
        dispatch(UpdateFavoriteClinician(false))
      }
    }
  }

  //close modal
  const onClose = () => {
    dispatch(ShowSystemErrorModal(false));
  }

  useEffect(() => {
    attachHoverforEllipsedText("vve-fname-lname");
  }, [clinicianListData]);

  useEffect(() => {
    latestPageSettings.current = pageConfig;
  }, [pageConfig]);


  const filteredClinicianList = useMemo(() => {
    if (isFavoriteToggle) {
      return favoriteData;
    }
    return clinicianListData;
  }, [clinicianListData, isFavoriteToggle, favoriteData]);

  const clinicianList = useMemo(() => filteredClinicianList.map((item, index) => (
    <ListGroup.Item
      action="true"
      key={index}
      role="button"
      className={`${selectedClinicianData && index === selectedItemIndex
        ? "vve-oncall-selected-clinician"
        : ""
        }`}
    >
      <div
        className="d-flex align-items-center"
        data-testid={`clinicianItem-${index}`}
        onClick={() => {
          clinicianListClicked(index, item);
        }}
      >
        <div
          className={`vve-clinician-icon-container ${item.isCurrentlyOnCall && !hideOncall ? "vve-clinician-oncall-live" : ""
            }`}
        >
          <Image
            className="vve-clinician-icon"
            src={item.imageURL ? item.imageURL : avatar}
            role="img"
            defaultImgSrc={getDefaultSrc}
            alt="clinician-image.svg"
            loading="lazy"
          />
        </div>
        <div
          className={`ms-3 vve-clinician-panel ${item.isCurrentlyOnCall ? "vve-clinician-oncall-live" : ""
            }`}
        >
          <div className="vve-clinician-name">
            <p className="vve-fname-lname">{`${item.lastName}, ${item.firstName}`}</p>
          </div>
          <div className="vve-clinician-role-medcentre-region-text">
            <p className="vve-clinician-role">
              {getJobDescriptionTitle(item)}
            </p>
            <p className="vve-clinician-medcentre">
              {item.department?.mc.name}
            </p>
            <p className="vve-clinician-region">{item.region}</p>
          </div>
        </div>
      </div>
      <IfCondition condition={featureFlags?.favorites}>
        <div className="vve-star-selection-area" onClick={() => handleFavourite(`${item?.isFavorite ? 'remove' : 'add'}`, item?.acctno)}>
          <Image
            className={item?.isFavorite ? "vve-filledStar-icon" : "vve-unfilledStar-icon"}
            src={item?.isFavorite ? filledStar : unfilledStar}
            role="img"
            alt={item?.isFavorite ? "filledStar.svg" : "unfilledStar.svg"}
          />
        </div>
      </IfCondition>
    </ListGroup.Item>
  )), [filteredClinicianList, selectedClinicianData]);

  return (
    <div>
      <IfCondition condition={featureFlags?.favorites}>
        <Toggle setFavoriteData={setFavoriteData} setLoaderScroll={setLoaderScroll} fetchClinicianList={fetchClinicianList} />
      </IfCondition>
      <div className="vve-clinician-list-container">
        {loader ? (
          <Loader showLoader={loader} />
        ) : clinicianListData?.length === 0 ? ( //no results found
          <div
            className="vve-clinician-no-results-found"
            data-testid="noResult"
          ></div>
        ) : (
          <>
            <ListGroup
              id="vve-clinician-list"
              data-testid="list-container"
              className={`vve-clinician-list${loaderScroll ? " opacity-25" : ""}`}
              role="document"
            >
              {clinicianList}
            </ListGroup>
            <ScrollToBottomDetector
              containerRefId="vve-clinician-list"
              onCallBack={handleScroll}
            />
            <Loader showLoader={loaderScroll} className="vve-loader-custom" />
          </>
        )}
      </div>

      {showSystemErrorModal && <DialogBox
        showModal={showSystemErrorModal}
        modalTitle={translations.SYSTEM_ERROR_TITLE}
        modalBody={translations.SYSTEM_ERROR_BODY}
        onClose={onClose}
      />
      }

    </div>
  );
};

export default ClinicianList;

const GUEST_INVITE_TEXT = 'SMS';
const GUEST_INVITE_EMAIL = 'EMAIL';
const GUEST_ENABLE_MOBILE = 'mobile';
const GUEST_ENABLE_EMAIL = 'email';
const GUEST_INVITE_AUDIO = 'AUDIO';
const RADIO_BUTTONS = [
    {
        label: 'Video',
        name: 'guestInvitationType',
        value: GUEST_INVITE_TEXT,
        checked: true
    },
    {
        label: 'Audio',
        name: 'guestInvitationType',
        value: GUEST_INVITE_AUDIO,
        checked: false
    }
];

const GUEST_INVITE_ENTER_MOBILE = "Enter mobile number";
const GUEST_INVITE_ENTER_EMAIL = "Enter email address";
const GUEST_INVITE_MOBILE_LINK = "Send link by text message";
const GUEST_INVITE_EMAIL_LINK = "Send link by email";

const GUEST_OPTIONS_LIST = [
    "guest-by-sms",
    "guest-by-phone",
    "guest-by-email",
];

const SUPPORT_OPTIONS_LIST = [
    "support"
]

const GUEST_AUDIO_INVITATION = 'GUEST_AUDIO';
const GUEST_PATIENT_VIDEO_INVITATION = 'GUEST_PATIENT_VIDEO';

const MULTI_INVITE_TYPE = "multiple_guest_invitation";
const MULTI_INVITE_OPTIONS = {
    headerText: "The guest will join via video.",
    infoText: "Send up to 5 text or email invitations simultaneously. Ensure that the same contact information is not being used in more than one field.",
    validationErrorText: "Enter a valid email or mobile number",
    inviteeCountLimit: 5,
    inviteeControlKeyPrefix: "inviteeControl",
    inviteeControlPlaceholder: "Enter mobile number or email",
};
const NOTIFICATION_SERVICE_DOWN_MODAL_CONTEXT = "notificationServiceDown";
const MULTI_INVITE_SPINNER_MODAL_CONTEXT = "multiInviteSpinner";
const MULTI_INVITE_FAILED_MODAL_CONTEXT = "multiInviteFailedModalContext";

const MULTI_INVITE_STATUS_CHECK_CANCEL = "CANCEL";

const ALLOWED_POLLING_STATUSES = ["CREATED", "PROCESS", "PROCESSING", "IN_PROCESS"];
const DISPLAY_FAILED_NOTIFICATIONS_STATUSES = ["CREATED", "FAILED", "BOUNCED_BACK", "PROCESS", "PROCESSING", "IN_PROCESS", "CANCELLED"];
const NOTIFICATIONS_SENT_STATUSES = ["SENT", "DELIVERED"];

const C2C_NOTIFICATION_TYPE_MAP = {
    email: "EMAIL",
    sms: "PHONE_NUM"
};

const PHONE_NOTIFICATION_TYPES = [GUEST_INVITE_TEXT, C2C_NOTIFICATION_TYPE_MAP.sms];
const MAX_TIME_LIMIT_CHECKER_INTERVAL = 500;
const POLLING_START_DELAY = 2000;

const GUEST = 'GUEST';
const PATIENT = 'PATIENT';

const PLACEHOLDER_MOBILE_EMAIL = 'Enter mobile number or email';
const PLACEHOLDER_MRN = 'Enter MRN';
const PLACEHOLDER_NAME = 'Enter Name';

const INPUT_NUMBER_EMAIL = 'numberOrEmail';
const INPUT_MRN = 'MRN';
const INPUT_NAME = 'name';

const ERR_MSG_NUMBER_MOBILE = 'Enter a valid email or mobile number';
const ERR_MSG_MRN_NAME = 'Enter a valid MRN ID or Name';

const ALT_PERSON_ADD_ICO = "person-add-icon";
const ALT_PERSON_REMOVE_ICO = "person-remove-icon";
const ALT_ERROR_CIRCLE_ICO = "error-circle";



export {
    GUEST_INVITE_TEXT,
    GUEST_INVITE_EMAIL,
    RADIO_BUTTONS,
    GUEST_INVITE_ENTER_MOBILE,
    GUEST_INVITE_ENTER_EMAIL,
    GUEST_INVITE_MOBILE_LINK,
    GUEST_INVITE_EMAIL_LINK,
    GUEST_INVITE_AUDIO,
    GUEST_OPTIONS_LIST,
    GUEST_AUDIO_INVITATION,
    GUEST_ENABLE_MOBILE,
    GUEST_ENABLE_EMAIL,
    MULTI_INVITE_TYPE,
    MULTI_INVITE_OPTIONS,
    SUPPORT_OPTIONS_LIST,
    NOTIFICATION_SERVICE_DOWN_MODAL_CONTEXT,
    MULTI_INVITE_SPINNER_MODAL_CONTEXT,
    MULTI_INVITE_FAILED_MODAL_CONTEXT,
    MULTI_INVITE_STATUS_CHECK_CANCEL,
    ALLOWED_POLLING_STATUSES,
    DISPLAY_FAILED_NOTIFICATIONS_STATUSES,
    NOTIFICATIONS_SENT_STATUSES,
    MAX_TIME_LIMIT_CHECKER_INTERVAL,
    POLLING_START_DELAY,
    C2C_NOTIFICATION_TYPE_MAP,
    PHONE_NOTIFICATION_TYPES,
    GUEST,
    PATIENT,
    PLACEHOLDER_MOBILE_EMAIL,
    PLACEHOLDER_MRN,
    PLACEHOLDER_NAME,
    INPUT_NUMBER_EMAIL,
    INPUT_MRN,
    INPUT_NAME,
    ERR_MSG_NUMBER_MOBILE,
    ERR_MSG_MRN_NAME,
    ALT_PERSON_ADD_ICO,
    ALT_PERSON_REMOVE_ICO,
    ALT_ERROR_CIRCLE_ICO,
    GUEST_PATIENT_VIDEO_INVITATION
};

import React from "react";
import { Card, Table } from "react-bootstrap";
import Button from '../Button/Button';
import BUTTON_CONFIG from '../../constants/button.constants';
import { ATTENDEE_SUMMARY, JOIN_TIME, LEAVE_TIME, TIME_ATTENDED ,TOTAL_TIME_ATTENDED} from "../../constants/report.constant";

const ToolTipModal = ({ isOpen, onClose, participantData }) => {
  // If modal is not open, return null
  if (!isOpen) return null;

  
  return (
    <Card className="vve-tooltip-card">
      <Card.Header><span className="vve-card-text-bold">{ATTENDEE_SUMMARY}</span> {participantData.name}</Card.Header>
      <Card.Body>
        <Table hover bordered className="vve-tool-tip-table">
          <thead>
            <tr>
              <th>{JOIN_TIME}</th>
              <th>{LEAVE_TIME}</th>
              <th>{TIME_ATTENDED}</th>
            </tr>
          </thead>
          <tbody>
          {participantData.dropRejoinTime?.map((data) => (
              <tr>
                <td>{data.joinTime}</td>
                <td>{data.leaveTime}</td>
                <td>{data.timeAttended}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    <span className="vve-total-attended-text">{TOTAL_TIME_ATTENDED} {participantData.totalActiveUserTime}</span> 
      <Card.Footer>
       <Button class='btn btn-primary btn-lg vve-close-btn' onClick={onClose}>
      { BUTTON_CONFIG.CLOSE_BUTTON.title }
      </Button>
      </Card.Footer>
    </Card>
  );
};

export default ToolTipModal;
